
import { defineComponent, ref } from 'vue'
import { kebabCase } from 'lodash'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import { detailsGridSidebarClass } from '@/definitions/_general/_data/general'
import type { PropType } from 'vue'
import { useModes } from '@/compositions/modes'

export type SidebarSectionType = {
  label: string;
  facet?: number;
  expanded?: boolean;
  openModalBtnLabel?: string;
}

export default defineComponent({
  components: {
    TmButton,
    TmExpansion,
    TmScrollbar,
  },
  props: {
    noPadding: {
      type: Boolean,
    },
    noScroll: {
      type: Boolean,
    },
    smallHeader: {
      type: Boolean,
    },
    sidebarSections: {
      type: Array as PropType<SidebarSectionType[]>,
      default: () => [],
    },
  },
  emits: ['open-modal'],
  setup(props) {
    const { isEmptyMode } = useModes()
    const sectionsExpands = ref(props.sidebarSections.map((section) => Boolean(section.expanded)))

    return {
      sectionsExpands,
      isEmptyMode,
      kebabCase,
      detailsGridSidebarClass,
    }
  },
})
