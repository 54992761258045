
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

type SystemInfoType = {
  label: string;
  text: string;
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<SystemInfoType[]>,
    },
  },
})
