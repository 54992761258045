import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between align-center w100pr" }
const _hoisted_2 = { class: "headline-18" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_account_details = _resolveComponent("account-details")!
  const _component_details_grid_sidebar = _resolveComponent("details-grid-sidebar")!

  return (_openBlock(), _createBlock(_component_details_grid_sidebar, null, {
    "sidebar-header": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.heading), 1),
        _createVNode(_component_tm_button, {
          "icon-only": "",
          flat: "",
          onClick: _ctx.openEditUserModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "edit",
              size: "xLarge",
              class: "distinct--text"
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_account_details, _mergeProps({
        "title-field": _ctx.account.title,
        "verify-email": "",
        "verify-phone": "",
        "status-static": ""
      }, _ctx.account), {
        "verify-phone": _withCtx(() => [
          _renderSlot(_ctx.$slots, "verify-phone")
        ]),
        "verify-email": _withCtx(() => [
          _renderSlot(_ctx.$slots, "verify-email")
        ]),
        _: 3
      }, 16, ["title-field"])
    ]),
    _: 3
  }))
}