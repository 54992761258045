
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmCountdown,
    CodeConfirmation,
  },
  emits: ['cancel-request'],
})
