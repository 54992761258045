import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67e589eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-system-info" }
const _hoisted_2 = { class: "tm-system-info__label" }
const _hoisted_3 = { class: "tm-system-info__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "tm-system-info__item"
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(item.text), 1)
      ]))
    }), 128))
  ]))
}