import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["details-grid", {
      'details-grid--left-sidebar': _ctx.leftSidebar,
      'details-grid--small-sidebar': _ctx.smallSidebar,
      'details-grid--loading-sidebar': _ctx.loadingSidebar,
    }])
  }, [
    (!_ctx.showNavigation || _ctx.currentNavigationView === 'default')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["details-grid__content", {
        'details-grid__content--wide': _ctx.wide,
      }])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.hideSidebar && _ctx.$slots.sidebar && (!_ctx.showNavigation || _ctx.currentNavigationView === 'sidebar'))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "sidebar",
          class: _normalizeClass(["details-grid__sidebar", {
        [`details-grid__sidebar--${_ctx.sidebarSize}`]: _ctx.sidebarSize,
        'details-grid__sidebar--no-padding': _ctx.withoutPadding,
        'details-grid__sidebar--wide': _ctx.wide,
      }])
        }, [
          _renderSlot(_ctx.$slots, "sidebar", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}