import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06573f52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details-grid-sidebar__body" }
const _hoisted_2 = {
  key: 0,
  class: "details-grid-sidebar__sections"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.detailsGridSidebarClass,
      { 'details-grid-sidebar--no-padding': _ctx.noPadding },
    ])
  }, [
    (_ctx.$slots['sidebar-header'])
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["details-grid-sidebar__header", {
        'details-grid-sidebar__header--small': _ctx.smallHeader,
      }])
        }, [
          _renderSlot(_ctx.$slots, "sidebar-header", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.noScroll ? 'div' : 'tm-scrollbar'), {
        class: _normalizeClass({'h100pr': _ctx.noScroll})
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.sidebarSections.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarSections, (section, i) => {
                  return (_openBlock(), _createBlock(_component_tm_expansion, _mergeProps({
                    key: i,
                    modelValue: _ctx.sectionsExpands[i],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.sectionsExpands[i]) = $event),
                    expanded: section.expanded
                  }, section), _createSlots({
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, `sidebar-section-${_ctx.kebabCase(section.label)}`, {}, undefined, true)
                    ]),
                    _: 2
                  }, [
                    (section.openModalBtnLabel)
                      ? {
                          name: "tm-expansion-btn",
                          fn: _withCtx(() => [
                            (_ctx.sectionsExpands[i] && !_ctx.isEmptyMode)
                              ? (_openBlock(), _createBlock(_component_tm_button, {
                                  key: 0,
                                  "text-link": "",
                                  class: "primary--text",
                                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('open-modal')), ["stop"]))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(section.openModalBtnLabel), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ])
                        }
                      : undefined
                  ]), 1040, ["modelValue", "onUpdate:modelValue", "expanded"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"]))
    ])
  ], 2))
}