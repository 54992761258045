
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TwoFactorAuthentication',
  components: {
    TmButton,
    WhiteBlock,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    myAccount: {
      type: Boolean,
    },
  },
  setup(props) {
    const enable = ref(props.enabled)
    const router = useRouter()

    const { openBackupVerificationCodesModal, openChangeAuthenticationModal, openDisableTwoFactorAuthenticationModal } = useAccountOpenModal()
    const handleChangeAuthentication = () => {
      openChangeAuthenticationModal(() => {
        router.push({ name: 'base.admin.twoFactorAuthenticationFlow' })
      })
    }
    const handle2fa = () => {
      openDisableTwoFactorAuthenticationModal(() => {
        setTimeout(() => {
          enable.value = !enable.value
        }, 2000)
      })
    }

    return {
      enable,
      openBackupVerificationCodesModal,
      handleChangeAuthentication,
      handle2fa,
    }
  },
})
