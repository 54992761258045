
import { defineComponent, ref } from 'vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import LoginHistoryTable from '@/components/pages/account/profile/LoginHistoryTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { TableItemType } from '@/definitions/account/profile/types'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import TmButton from '@/components/shared/TmButton.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'

export default defineComponent({
  components: {
    TmButton,
    WhiteBlock,
    LoginHistoryTable,
  },
  props: {
    hideLogout: {
      type: Boolean,
    },
    user: {
      type: String,
    },
    myAccount: {
      type: Boolean,
    },
  },
  setup() {
    const { openUserLogOutModal } = useAccountOpenModal()

    const historyHeaders = ref<TableHeaders[]>([
      { text: 'Country', value: 'country', width: '30%' },
      { text: 'IP', value: 'ip', hideSortable: true },
      { text: 'Login date', value: 'date-created', format: (val: string) => formatDate(val) },
    ])
    const history = ref<TableItemType[]>(getTableData('loginHistory'))

    return {
      historyHeaders,
      history,
      openUserLogOutModal,
    }
  },
})
