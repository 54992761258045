
import { computed, defineComponent, ref } from 'vue'
import TwoFactorAuthentication from '@/components/pages/account/profile/TwoFactorAuthentication.vue'
import AccountProfileSidebar from '@/components/pages/account/profile/AccountProfileSidebar.vue'
import LoginHistory from '@/components/pages/account/profile/LoginHistory.vue'
import DetailsGrid from '@/components/shared/templates/details/DetailsGrid.vue'
import { useRoute } from 'vue-router'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'
import { useModes } from '@/compositions/modes'

const activeStatus = {
  name: 'active',
  outline: true,
  color: 'success',
}
const deactivatedStatus = {
  name: 'deactivated',
  outline: true,
  color: 'red',
}
const invitedStatus = {
  name: 'invited',
  outline: true,
  color: 'blue',
}

export default defineComponent({
  name: 'Profile',
  components: {
    AccountProfileSidebar,
    TmButton,
    TmEmptyState,
    TwoFactorAuthentication,
    DetailsGrid,
    LoginHistory,
  },
  setup() {
    const route = useRoute()
    const status = computed(() => {
      if (route.query?.status === 'invited') return invitedStatus
      else if (route.query?.status === 'deactivated') return deactivatedStatus
      else return activeStatus
    })

    const { isErrorMode } = useModes()
    const description = computed(() =>
      `The invitation ${isErrorMode.value ? 'has expired on' : 'is valid until'} 18 Jul 2022 at 12:56 pm.`
    )

    const accountInvitedTemplate = ref({
      firstName: '',
      lastName: '',
      username: '',
      title: '',
      role: 'Agent',
      team: [
        'Admin',
        'Marketing',
      ],
      status: status.value,
      email: 'ruth.agent@textmagic.com',
      phone: '',
      address: '',
    })

    const accountTemplate = ref({
      firstName: 'Ruth',
      lastName: 'Adkins',
      username: 'ruth-adkins',
      title: 'Product manager',
      role: 'Agent',
      team: [
        'Admin',
        'Marketing',
      ],
      status: status.value,
      email: 'ruth.agent@textmagic.com',
      phone: '(427) 624-0500',
      address: '971 Cummings Flats, London',
    })

    const account = ref(route.query?.status === 'invited' ? accountInvitedTemplate : accountTemplate)

    const { openCancelInvitationModal, openResendInvitationModal } = useAccountOpenModal()

    return {
      account,
      route,
      description,
      openCancelInvitationModal,
      openResendInvitationModal,
      isErrorMode,
    }
  },
})
