
import { defineComponent, ref, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import VerifyModal from '@/components/modals/account/VerifyModal.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmFieldsContainer,
    TmStatus,
    TmButton,
    TmSystemInfo,
    VerifyModal,
  },
  props: {
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    username: {
      type: String,
    },
    titleField: {
      type: String,
    },
    role: {
      type: String,
    },
    status: {
      type: Object,
    },
    team: {
      type: Array,
    },
    email: {
      type: String,
    },
    phone: {
      type: String,
    },
    phoneCountry: {
      type: String,
    },
    address: {
      type: String,
    },
    verifyEmail: {
      type: Boolean,
    },
    verifyPhone: {
      type: Boolean,
    },
    statusStatic: {
      type: Boolean,
    },
    statusHide: {
      type: Boolean,
    },
  },
  setup(props: any) {
    const firstNameValue = ref(props.firstName)
    const lastNameValue = ref(props.lastName)
    const fullNameValue = ref(props.firstName ? firstNameValue.value + ' ' + lastNameValue.value : '')
    const usernameValue = ref(props.username)
    const titleFieldValue = ref(props.titleField)
    const roleValue = ref(props.role)
    const teamValue = ref(props.team)
    const statusValue = ref(props.status)
    const addressValue = ref(props.address)
    const emailValue = ref([{ email: props.email }])
    const phoneValue = ref([{ phone: props.phone }])

    const roleList = ref([
      'Administrator',
      'Agent',
      'Supervisor',
      'Accountant',
    ])

    const teamList = ref([
      'Admin',
      'Agent',
      'Supervisor',
      'Accountant',
      'Marketing',
    ])

    const statusList = ref([
      {
        name: 'Active',
        outline: true,
        color: 'green',
      },
      {
        name: 'Inactive',
        outline: true,
        color: 'orange',
      },
    ])

    const systemInfo = computed(() => {
      if (props.status.name === 'invited') {
        return [
          {
            label: 'Invited',
            text: '11 Jul 2022, 3:14 am',
          },
          {
            label: 'Invited by',
            text: 'James Smith',
          },
          {
            label: 'Updated',
            text: 'Yesterday, 11:33 pm',
          },
          {
            label: 'Updated by',
            text: 'Robert Overit',
          },
        ]
      } else if (props.status.name === 'deactivated') {
        return [
          {
            label: 'Last active',
            text: '11 Sep, 3:13 am',
          },
          {
            label: 'Joined',
            text: '11 Jul 2021, 3:14 am',
          },
          {
            label: 'Invited by',
            text: 'James Smith',
          },
          {
            label: 'Updated',
            text: 'Yesterday, 11:33 pm',
          },
          {
            label: 'Updated by',
            text: 'Robert Overit',
          },
          {
            label: 'Deactivated',
            text: '13 Sep, 4:25 pm',
          },
          {
            label: 'Deactivated by',
            text: 'Robert Overit',
          },
        ]
      } else {
        return [
          {
            label: 'Last active',
            text: '2m ago',
          },
          {
            label: 'Joined',
            text: '11 Jul 2021, 3:14 am',
          },
          {
            label: 'Invited by',
            text: 'James Smith',
          },
          {
            label: 'Updated',
            text: 'Yesterday, 11:33 pm',
          },
          {
            label: 'Updated by',
            text: 'Robert Overit',
          },
        ]
      }
    })

    const verifyEmailValue = ref(props.verifyEmail)
    const verifyPhoneValue = ref(props.verifyPhone)

    const { isLgMax } = useBreakpoints()

    const styleProps = computed(() => {
      return {
        leftLabel: !isLgMax.value,
        inputContainerClass: isLgMax.value ? 'mx-n2' : '',
      }
    })

    return {
      firstNameValue,
      lastNameValue,
      fullNameValue,
      usernameValue,
      titleFieldValue,
      roleValue,
      teamValue,
      statusValue,
      emailValue,
      phoneValue,
      addressValue,
      roleList,
      teamList,
      statusList,
      verifyEmailValue,
      verifyPhoneValue,
      systemInfo,
      isLgMax,
      styleProps,
    }
  },
})
