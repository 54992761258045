
import type { PropType } from 'vue'
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

export default defineComponent({
  props: {
    leftSidebar: {
      type: Boolean,
    },
    smallSidebar: {
      type: Boolean,
    },
    withoutPadding: {
      type: Boolean,
    },
    loadingSidebar: {
      type: Boolean,
    },
    hideSidebar: {
      type: Boolean,
    },
    wide: {
      type: Boolean,
    },
    currentNavigationView: {
      type: String as PropType<'default' | 'sidebar'>,
      default: 'default',
    },
    showNavigation: {
      type: Boolean,
    },
  },
  setup() {
    const sidebarSize = ref<'md' | 'sm' | ''>('')
    const sidebar = ref()
    let resizeObserver: ResizeObserver

    const updateSidebarSize = () => {
      const width = sidebar.value?.offsetWidth

      if (width < 390 && width >= 320) {
        sidebarSize.value = 'md'
      } else if (width < 320) {
        sidebarSize.value = 'sm'
      } else {
        sidebarSize.value = ''
      }
    }

    onMounted(() => {
      resizeObserver = new ResizeObserver(() => {
        updateSidebarSize()
      })

      resizeObserver?.observe(sidebar.value)
    })

    onBeforeUnmount(() => {
      resizeObserver?.disconnect()
    })

    return {
      sidebarSize,
      sidebar,
    }
  },
})
