
import { defineComponent } from 'vue'
import AccountDetails from '@/components/pages/account/profile/AccountDetails.vue'
import TmButton from '@/components/shared/TmButton.vue'
import DetailsGridSidebar from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    AccountDetails,
    TmButton,
    DetailsGridSidebar,
  },
  props: {
    account: {
      type: Object,
    },
    heading: {
      type: String,
      default: 'User details',
    },
    editModalTitle: {
      type: String,
    },
    verifyEmailModalHintText: {
      type: String,
    },
    verifyPhoneModalHintText: {
      type: String,
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const openEditUserModal = () => openModal('workspaceEditUser', {
      modalTitle: props.editModalTitle,
      verifyEmailHintText: props.verifyEmailModalHintText,
      verifyPhoneHintText: props.verifyPhoneModalHintText,
    })

    return {
      openEditUserModal,
    }
  },
})
